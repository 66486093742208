<template>
    <b-card title="Additional Settings">
        <div class="mt-3">
            <div class="activity-content mt-2 mb-2">
                <h7 class="title-width">Data Order</h7> 
                <b-form-select class="box-width" v-model="modify.order" :options="order_options"></b-form-select>
            </div>
            <div class="activity-content">
                <h7 class="title-width">Premium Profile Amount</h7> 
                <b-form-input class="box-width" id="name-input" type="number" v-model="modify.premium_amount" />
            </div>
            <div class="activity-content mt-2">
                <h7 class="title-width">Match Payment Amount</h7> 
                <b-form-input class="box-width" id="name-input" type="number" v-model="modify.match_amount" />
            </div>
            <div class="activity-content mt-2">
                <h7 class="title-width">Free Hearts</h7> 
                <b-form-input class="box-width" id="name-input" type="number" v-model="modify.free_hearts" />
            </div>
            <div class="activity-content mt-2 mb-2">
                <h7 class="title-width">SMS gateway</h7> 
                <b-form-select class="box-width" v-model="modify.sms_gateway" :options="options"></b-form-select>
            </div>
            <b-button class="mt-1" variant="primary" @click="submitAdditionalSettings()">Submit</b-button>
        </div>
    </b-card>
</template>

<script> 
import {
BAvatar,BButton, BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BDropdown, BDropdownItem, BCard,
BModal,VBModal,BCardText, BFormTextarea
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import vSelect from "vue-select"
import Ripple from "vue-ripple-directive";
import {mapState} from "vuex";
import moment from "moment";
import ToastificationContent from "@core/components/toastification/ToastificationContent";

export default {
    components: {
        VueGoodTable,
        BAvatar,
        BCard,
        BBadge,
        BButton,
        BPagination,
        BFormGroup,
        BFormInput,
        BFormSelect,
        BDropdown,
        BDropdownItem,
        BModal,
        vSelect,
        BCardText,
        BFormTextarea
    },

    directives: {
        'b-modal': VBModal,
        Ripple,
    },

    data() {
        return {
            dir: false,
            modify: {
                order: null,
                sms_gateway: null,
                premium_amount: null,
                free_hearts: null,
                match_amount: null
            },
            order_options: [
                {
                    text: 'Ascending',
                    value: 'ascending'
                },
                {
                    text: 'Descending',
                    value: 'descending'
                }
            ],
            options: [
                {
                    text: 'Bdbulk SMS',
                    value: 'bdbulk_sms'
                },
                {
                    text: 'Twilio SMS',
                    value: 'twilio_sms'
                },
                {
                    text: 'QSMS',
                    value: 'q_sms'
                }
            ]
        }
    },

    computed: {
        ...mapState('settings', ["settings","submitError","submitLoading"]),
    
        direction() {
            if (store.state.appConfig.isRTL) {
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                this.dir = true
                return this.dir
            }
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.dir = false
            return this.dir
        },
    },

    watch: {
        settings(newValue, oldValue) {
            this.modify.order = this.settings.order
            this.modify.sms_gateway = this.settings.sms_gateway
            this.modify.premium_amount = this.settings.premium_amount
            this.modify.match_amount = this.settings.match_amount
            this.modify.free_hearts = this.settings.free_hearts
        }
    },

    created() {
        this.$store.dispatch('settings/fetchAdditionalSettings')
        this.modify.order = this.settings.order
        this.modify.sms_gateway = this.settings.sms_gateway
        this.modify.premium_amount = this.settings.premium_amount
        this.modify.match_amount = this.settings.match_amount
        this.modify.free_hearts = this.settings.free_hearts
    },

    methods: {
        submitAdditionalSettings() {
            this.$store.dispatch('settings/addAdditionalSettings', this.modify)
            .then(result=>{
                if(result.code == 200) {
                    this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                            title: `Success`,
                            icon: 'ThumbsUpIcon',
                            variant: 'success',
                            text: result.response,
                        }
                    })
                }
            })
        }
    }
}
</script>
  
<style lang="scss" scoped>
.no-outline{
    border:none !important;
}

.f20{
    font-size: 8px !important;
}

.vs__dropdown-menu {
    height: 200px;
}

.activity-content {
    font-size: medium;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.box-width {
    width: 400px;
}

@media screen and (max-width: 500px) {
    .box-width {
        width: 100%;
    }
}

@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';

</style>